.sidebar {
    background-color: #419af8;

    width: 24rem;
    border-radius: 0.8rem;
}

.q-arrow::before {
    content: "\f105" !important;
    font-family: "Font Awesome 5 Pro" !important;
    font-size: 1rem !important;
}
